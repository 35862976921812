<template>
    <transition name="rfade" mode="out-in">
            <img v-if="show" :src="urlo" :alt="desc" :title="desc" :class="classes" :id="i_d"/>
     </transition>
</template>

<script>
    export default
            {
                props: {
                    urlo: "",
                    desc: "",
                    classes: "",
                    i_d: ""
                },
                watch: {
                    urlo: function (val) {
                        this.show = false;
                        this.load();
                    }
                },
                data() {
                    return {
                        show: false
                    }
                },
                mounted() {
                    this.load();
                },
                methods: {
                    load: function () {
                        let pic = new Image();
                        let app = this;
                        pic.src = app.urlo;
                        pic.onload = function () {
                            app.show = true;
                        }
                    }
                }
            }
</script>

<style>

    .rfade-enter-active, .rfade-leave-active {
        transition: opacity 1s ease;
    }

    .rfade-enter,
    .rfade-leave-to { opacity: 0 }

    .rfade-leave,
    .rfade-enter-to { opacity: 1 }

</style>
