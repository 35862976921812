<template>
    <div ref="r_c" class="w-100 h-100">
        <canvas id="r_canvas1" :style="style" class="w-100 h-100"></canvas>
    </div>
</template>

<script>

    var Line = function (_x0, _y0, _x1, _y1, _r, _g, _b, _lineWidth) {
        this.x0 = _x0;
        this.x1 = _x1;
        this.y0 = _y0;
        this.y1 = _y1;
        this.visible = false;
        this.strokeStyle = "rgb(" + _r + "," + _g + "," + _b + ")";
        this.lineWidth = _lineWidth;
        this.r = _r;
        this.g = _g;
        this.b = _b;
        this.dr = 1; //direzione del shift colore: l'incremento che può essere negativo, positivo, 0
        this.dg = 1; //direzione del shift colore: l'incremento che può essere negativo, positivo, 0
        this.db = 1; //direzione del shift colore: l'incremento che può essere negativo, positivo, 0
    };

    Line.prototype.setColor = function (_r, _g, _b)
    {
        this.r = _r;
        this.g = _g;
        this.b = _b;
        this.strokeStyle = "rgb(" + _r + "," + _g + "," + _b + ")";
    };

    Line.prototype.setRandomColor = function ()
    {
        this.r = Math.floor(Math.random() * 255);
        this.g = Math.floor(Math.random() * 255);
        this.b = Math.floor(Math.random() * 255);
        this.strokeStyle = "rgb(" + this.r + "," + this.g + "," + this.b + ")";
    };

    Line.prototype.shiftColor = function () {
        if (this.r > 255 || this.r < 0)
            this.dr = -this.dr;
        if (this.g > 255 || this.g < 0)
            this.dg = -this.dg;
        if (this.b > 255 || this.b < 0)
            this.db = -this.db;
        this.r += this.dr;
        this.g += this.dg;
        this.b += this.db;

    };


    Line.prototype.move = function (_x0, _y0, _x1, _y1) {
        this.x0 = _x0;
        this.x1 = _x1;
        this.y0 = _y0;
        this.y1 = _y1;
    };

    Line.prototype.draw = function (ctx) { //draws on context
        if (this.visible) {
            this.strokeStyle = "rgb(" + this.r + "," + this.g + "," + this.b + ")";
            ctx.beginPath();
            ctx.strokeStyle = this.strokeStyle;
            ctx.lineWidth = this.lineWidth;
            ctx.moveTo(this.x0, this.y0);
            ctx.lineTo(this.x1, this.y1);
            ctx.stroke();
        }
    };


    export default
            {
                props: {
                },
                data() {
                    return {
                        txt: "",
                        width: 100,
                        height: 100,
                        style: "border: 1px solid #f00",
                        N: 50, //numero linee
                        counter: 0, //il counter che indica dove siamo nelle linee;
                        sideCounter: 0, //il counter che indica il lato da riempire
                        canvas: {}, //riferimento al canvas
                        ctx: {}, //riferimento al canvas context
                        storedLines: [] //le singole linee che andremo a disegnare
                    }
                },
                mounted() {
                    window.addEventListener('resize', this.onResize);
                    this.create();
                    window.setInterval(this.doit, 50);
                },

                methods: {
                    create() {
                        this.canvas = document.getElementById("r_canvas1");
                        this.ctx = this.canvas.getContext("2d");
                        this.ctx.canvas.width = window.innerWidth;
                        this.ctx.canvas.height = window.innerHeight;

                        var r = Math.floor(Math.random() * 255);
                        var g = Math.floor(Math.random() * 255);
                        var b = Math.floor(Math.random() * 255);
                        var dr = 255 / this.N;
                        var dg = -255 / this.N;
                        var db = 255 / this.N;

                        for (var i = 0; i <= this.N; i++) {
                            this.storedLines.push(new Line(0, (this.canvas.height / this.N) * i, (this.canvas.width / this.N) * i, this.canvas.height, r, g, b, 1));
                            if (r > 255 || r < 0)
                                dr = -dr;
                            if (g > 255 || g < 0)
                                dg = -dg;
                            if (b > 255 || b < 0)
                                db = -db;
                            r += dr;
                            g += dg;
                            b += db;
                            this.storedLines[i].visible = false;
                            this.storedLines[i].dr = dr;
                            this.storedLines[i].dg = dg;
                            this.storedLines[i].db = db;
                        }

                    },
                    onResize: function () {
                        var origW = this.ctx.canvas.width;
                        var origH = this.ctx.canvas.height;

                        var propW = window.innerWidth / origW;
                        var propH = window.innerHeight / origH;

                        this.ctx.canvas.width *= propW;
                        this.ctx.canvas.height *= propH;

                        for (var i = 0; i <= this.N; i++) {
                            this.storedLines[i].x0 *= propW;
                            this.storedLines[i].x1 *= propW;
                            this.storedLines[i].y0 *= propH;
                            this.storedLines[i].y1 *= propH;
                        }
                        this.drawLines();
                    },
                    drawLines: function () {
                        for (var i = 0; i <= this.N; i++) {
                            this.storedLines[i].draw(this.ctx);
                        }
                    },
                    doit: function () {
                        let app = this;
                        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

//                        this.write(this.txt);

                        if (!this.storedLines[this.counter].visible)
                            this.storedLines[this.counter].visible = true;

                        switch (this.sideCounter)
                        {
                            case 0:
                                this.storedLines[this.counter].move(0, (this.canvas.height / this.N) * this.counter, (this.canvas.width / this.N) * this.counter, this.canvas.height);
                                break;
                            case 1:
                                this.storedLines[this.counter].move((this.canvas.width / this.N) * this.counter, this.canvas.height, this.canvas.width, this.canvas.height - (this.canvas.height / this.N) * this.counter);
                                break;
                            case 2:
                                this.storedLines[this.counter].move(this.canvas.width, this.canvas.height - (this.canvas.height / this.N) * this.counter, this.canvas.width - (this.canvas.width / this.N) * this.counter, 0);
                                break;
                            case 3:
                                this.storedLines[this.counter].move(this.canvas.width - (this.canvas.width / this.N) * this.counter, 0, 0, (this.canvas.height / this.N) * this.counter);
                                break;
                        }

                        this.storedLines[this.counter].shiftColor();

                        this.storedLines.forEach(function (el) {
                            el.draw(app.ctx);
                        });


                        this.counter++;
                        if (this.counter >= this.N)
                        {
                            this.counter = 0;
                            this.sideCounter++;
                            if (this.sideCounter > 3) {
                                this.sideCounter = 0; //a che lato siamo
                                var r = Math.floor(Math.random() * 255);
                                var g = Math.floor(Math.random() * 255);
                                var b = Math.floor(Math.random() * 255);
                                var dr = 255 / this.N;
                                var dg = -255 / this.N;
                                var db = 255 / this.N;

//                                this.txt = "counter: " + this.counter + " r: " + r + " g: " + g + " b: " + b;

                                for (var i = 0; i <= this.N; i++) {
                                    if (r > 255 || r < 0)
                                        dr = -dr;
                                    if (g > 255 || g < 0)
                                        dg = -dg;
                                    if (b > 255 || b < 0)
                                        db = -db;
                                    r += dr;
                                    g += dg;
                                    b += db;
//                                    this.storedLines[i].visible = false;
                                    this.storedLines[i].dr = dr;
                                    this.storedLines[i].dg = dg;
                                    this.storedLines[i].db = db;
                                }
                            }

                        }
                    },
                    write: function (s) {
                        this.ctx.font = this.canvas.height / 25 + "px Verdana";
                        var gradient = this.ctx.createLinearGradient(0, 0, this.canvas.width, 0);
                        gradient.addColorStop("0", " magenta");
                        gradient.addColorStop("0.5", "blue");
                        gradient.addColorStop("1.0", "red");
                        this.ctx.textAlign = "end";
                        this.ctx.fillText(s, this.canvas.width - 20, this.canvas.height / 25 + 20);
                    }
                }

            }

</script>

<style>

</style>