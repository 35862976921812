<template>
    <transition name="rfade" v-if="show">
        <div v-bind:style="r_styleObject" :class="classes">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
    export default
            {
                props: {
                    urlo: String,
                    wid: String,
                    hig: String,
                    classes: String

                },
                data() {
                    return {
                        show: false,
                        r_styleObject: {
                            'background-image': 'url(/images/white.png)',
                            'background-position': 'center center',
                            'background-repeat': 'no repeat',
                            'position': 'relative',
                            'height': "20px",
                            'width': "100%",
                            'background-size': 'cover'
                        }
                    }
                },
                mounted() {
                    let pic = new Image();
                    let app = this;
                    if (app.urlo === "random") {
                        axios.get('/ap/picrandom/2')
                                .then(function (resp) {
                                    pic.src = resp.data;
                                })
                                .catch(function (error) {
                                    pic.src = '/uploads/standard/DSC00982.jpg';
                                });
                    } else {
                        pic.src = app.urlo;
                    }
                    pic.onload = function () {
                        app.r_styleObject['background-image'] = 'url(' + pic.src + ')';
                        app.r_styleObject.height = app.hig;
                        app.r_styleObject.width = app.wid;
                        app.$forceUpdate();
                        app.$parent.$emit('pic-p', this.naturalWidth / this.naturalHeight); //fa emettere al componente padre proporzione h/w
                        app.show = true;
                    }
                }
            }
</script>

<style>

    .rfade-enter-active, .rfade-leave-active {
        transition: opacity 3s ease;
    }

    .rfade-enter,
    .rfade-leave-to { opacity: 0 }

    .rfade-leave,
    .rfade-enter-to { opacity: 1 }

</style>
